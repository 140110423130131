/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body{
    margin:0;
    background-color: #f5f7f9;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container{
    padding: 24px;
    font-family: "Roboto", sans-serif;
    color: #213454;
    width: 100%;
}

.titulo{
    font-size: 40px !important;
    font-weight: 600;
}

tr{
    border-bottom: 1px solid #d2dae8;
}

td{
    line-height: normal;
}

.icon{
    cursor: pointer;
    margin-right: 10px;
}

.icon:hover{
    color: #004E82;
}

.button_primary{
    width: 100%;
    background-color: #004E82;
    color: white;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: none;
    border-radius: 8px;
}